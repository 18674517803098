import { get } from 'lodash';
import { Button } from 'primereact/button';
import React, { useState } from 'react';
import { useAppContext } from '../layout/AppWrapper';
import { PostPdfCall } from '../api/ApiKit';

interface Options {
    url: string,
    type: string,
    ids: number[],
    onSuccess?: any,
    onError?: any
}

const DownloadBarcodeButton = ({ url = '', type = '', ids = [], onSuccess = () => {}, onError = (message: any) => {} }: Options) => {
    const { setAlert, setLoading } = useAppContext();
    const [isDownloading, setIsDownloading] = useState<boolean>(false);
    const generateBarcodes = async () => {
        if (isDownloading) return; // Prevent duplicate requests

        setIsDownloading(true);
        setLoading(true);

        try {
            const result = await PostPdfCall(url, { type, ids });

            if (result && result.code === 'FAILED') {
                setAlert('error', result.message);
                onError(result.message);
            } else {
                onSuccess();
            }
        } catch (error: any) {
            setAlert('error', error.message || 'Something went wrong!');
            onError(error.message);
        } finally {
            setIsDownloading(false);
            setLoading(false);
        }
    };

    return (
        <Button
        type="button"
        size="small"
        icon={`pi ${isDownloading ? 'pi-spin pi-spinner' : 'pi-download'}`}
        label="Barcodes"
        onClick={generateBarcodes}
        data-pr-tooltip="Generate barcodes"
        disabled={isDownloading}
    />
    );
};

export default DownloadBarcodeButton;
