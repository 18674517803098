import { SalesReturns } from ".";

export const DEFAULT_SALES_RETURNS: SalesReturns = {
    rmaId: null,
    rmaNumber: null,
    companyId: null,
    soId: null,
    vendorId: null,
    rmaDate: null,
    statusId: null,
    reasonId: null,
    trackingTypeId: null,
    trackingRef: null,
    items: []
};