import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

export const downloadExcel = ({ data, name = 'download' }: { data: any[], name?: any }) => {
    // Convert JSON data to a worksheet
    const worksheet = XLSX.utils.json_to_sheet(data);

    // Create a new workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Write the workbook and create a Blob
    const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });

    // Trigger a download
    saveAs(blob, `${name}.xlsx`);
};
