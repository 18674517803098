import { useEffect, useRef, useState } from "react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { debounce, filter, find, get, groupBy, map, set, sumBy } from "lodash";
import moment, { isMoment } from "moment";
import { Button } from "primereact/button";
import { useAppContext } from "../../layout/AppWrapper";
import Sidebar from "../Sidebar";
import { ACTIONS, constant } from "../../utils/constant";
import { Category, CustomResponse, ProductItem, SalesReturns } from "../../types";
import { GetCall, PostCall, PutCall } from "../../api/ApiKit";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { confirmDialog } from "primereact/confirmdialog";


interface EditorOptions {
    isVisible: boolean,
    action?: any,
    salesReturn?: SalesReturns | null,
    onClose: (isLoad: boolean) => void
}

const defaultSalesReturns: SalesReturns = {
    rmaId: null,
    rmaNumber: null,
    companyId: null,
    soId: null,
    vendorId: null,
    rmaDate: null,
    statusId: null,
    reasonId: null,
    trackingTypeId: null,
    trackingRef: null,
    items: []
}

export default function SalesReceiveEditor({ isVisible = false, action = '', salesReturn, onClose = (isLoad) => { } }: EditorOptions) {
    const { user, isLoading, setLoading, setScroll, setAlert } = useAppContext();
    const [dialogVisible, setDialogVisible] = useState<boolean>(false);
    const [isSubmitted, setSubmitted] = useState<boolean>(false);

    const [salesReturnDetails, setSalesReturn] = useState<SalesReturns>({ ...defaultSalesReturns });
    const [selectedCat, setSelectedCat] = useState<Category | null>(null);
    const [selectedSKU, setSelectedSKU] = useState<any>(null);
    const [binNumber, setBinNumber] = useState('');
    const [REID, setREID] = useState('');
    const [inputREIDValue, setInputREIDValue] = useState('');
    const [binDetails, setBinDetails] = useState<any>(null);
    const [items, setItems] = useState<any[]>([]);
    const [skuList, setSKUList] = useState<any[]>([]);
    const [skuSearch, setSKUSearch] = useState<any>('');
    const [conditionId, setConditionId] = useState(null);
    const [conditions, setConditions] = useState<any[]>([]);

    useEffect(() => {
        if (isVisible) {
            setDialogVisible(true);
            fetchConditions();
        }
        else {
            setDialogVisible(false)
        }
    }, [isVisible]);

    useEffect(() => {
        if (salesReturn) {
            setSalesReturn(salesReturn)
        }
        else {
            setSalesReturn({ ...defaultSalesReturns })
        }
    }, [salesReturn])

    // fetch bin details
    useEffect(() => {
        if (binNumber) {
            fetchBin(binNumber)
        }
        else {
            setBinDetails(null);
        }
    }, [binNumber])

    // auto receive product item when form is complete
    useEffect(() => {
        addNewItem()
    }, [selectedSKU, binNumber, conditionId]);

    const fetchBin = debounce(async (barcode) => {
        if (!barcode) return;
        const companyId = get(user, 'company.companyId');
        setLoading(true);
        const response: CustomResponse = await GetCall(`/company/${companyId}/barcode/${barcode}`);
        if (response.code == 'SUCCESS') {
            if (get(response, 'data.barcodeType') == "BIN") {
                setBinDetails(response.data)
            }
            else {
                // setAlert('error', 'Please scan valid BIN.')
            }
        } else {
            // setAlert('error', 'Bin not found')
        }
        setLoading(false);
    });

    const fetchConditions = async () => {
        if (!user?.company?.companyId) {
            return;
        }
        setLoading(true);
        const response: CustomResponse = await GetCall(`/company/${user?.company?.companyId}/master-codes?codeType=${constant.SYSTEM_MSTR_CODE.ReturnConditions}`);
        if (response.code == 'SUCCESS') {
            setConditions(response.data);
        } else {
            setConditions([]);
        }
        setLoading(false);
    };

    const updateItem = async (key: string, value: any) => {
        const _pOrder: SalesReturns = JSON.parse(JSON.stringify(salesReturnDetails));
        set(_pOrder, key, value);
        setSalesReturn(_pOrder);
    }

    const handleKeyPress = (event: any) => {
        if (event.key === "Enter") {
            setREID(event.target.value)
        }
    };

    const closeIcon = () => {
        onClose(false);
    }

    const onSave = async () => {
        if (items.length == 0) {
            return;
        }
        setLoading(true);
        const payload = items.map((item: any) => ({
            skuId: item.skuId,
            binNumber: item.binNumber,
            conditionId: item.conditionId,
            REID: item.REID
        }))
        const response: CustomResponse = await PostCall(`/company/${user?.company?.companyId}/receive-sales-returns/${salesReturn?.rmaId}`, payload);
        if (response.code == 'SUCCESS') {
            onClose(false);
        } else {
            setAlert('error', response.message)
        }
        setLoading(false);
    }

    const confirmDelete = (item?: ProductItem) => {
        confirmDialog({
            className: 'confirm-dialog',
            message: `Do you really want to delete this product : ${item?.product.name} [LPN: ${item?.REID}]?`,
            header: "Confirmation",
            icon: "pi pi-exclamation-triangle text-red",
            position: 'top',
            accept: () => {
                if (item) {
                    setItems(items.filter((ele) => ele.REID != item.REID))
                }
            },
        });
    }

    const addNewItem = () => {
        if (selectedSKU && binNumber && conditionId) {
            const findItem = find(items, { REID: get(selectedSKU, 'pItem.REID') })
            const cond = find(conditions, { masterCodeId: conditionId })
            if (!findItem) {
                let item = {
                    skuId: selectedSKU.skuId,
                    binNumber: binNumber,
                    conditionId: conditionId,
                    REID: get(selectedSKU, 'pItem.REID'),
                    product: {
                        name: get(selectedSKU, 'product.name'),
                        sku: get(selectedSKU, 'product.sku'),
                        skuId: get(selectedSKU, 'product.skuId'),
                    },
                    bin: {
                        binNumber: binNumber,
                    },
                    condition: cond
                };
                setItems([...items, item])
            }
            setSelectedSKU(null);
            setConditionId(null);
        }
    }

    const valueTemplate = (option: any) => {
        if (option) {
            return (
                <p><strong>[LPN: {get(option, 'pItem.REID')}]</strong> {get(option, 'product.name')}</p>
            );
        }
        return <span>Select a product</span>;
    };

    return (
        <>
            <Sidebar
                isVisible={dialogVisible}
                action={ACTIONS.ADD}
                width={'60vw'}
                title={`Receive ${salesReturn?.rmaNumber}`}
                closeIcon={closeIcon}
                onSave={onSave}
                content={<>
                    <div className="col-6">
                        <div className="field  mb-0">
                            <div className="flex justify-content-between mb-2">
                                <label htmlFor="reid">Bin <span className="text-red-500">*</span></label>
                                {
                                    get(binDetails, 'binNumber') == binNumber && +get(binDetails, 'binCapacity.value', 0) > -1 && <label>{get(binDetails, 'capacityUsed', 0)}/{get(binDetails, 'binCapacity.value', 0)}</label>
                                }
                            </div>
                            <IconField>
                                <InputIcon className="pi pi-qrcode"> </InputIcon>
                                <InputText id="bin" className="w-full" value={binNumber} placeholder="Scan or Enter bin id" onChange={(e) => setBinNumber(e.target.value)} />
                            </IconField>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="field mb-0">
                            <label htmlFor="sku">
                                SKU <span className="text-red-500">*</span>
                            </label>
                            <Dropdown
                                value={selectedSKU}
                                filter
                                showClear
                                onChange={(e) => setSelectedSKU(e.value)}
                                options={salesReturn?.items.filter((item) => !map(items, 'REID').includes(item.pItem?.REID))}
                                optionLabel="sku"
                                placeholder="Select a product"
                                className={`w-full`}
                                required={true}
                                filterBy='REID'
                                valueTemplate={valueTemplate}
                                itemTemplate={(option) => <>
                                    <small className='small-desc'>LPN: {get(option, 'pItem.REID')}</small>
                                    <p>{get(option, 'product.name')}</p>
                                </>}
                            />
                        </div>
                    </div>

                    <div className="col-6">
                        <div className="field">
                            <label htmlFor="name3" className="w-full">
                                Condition<span className='text-red'>*</span>
                            </label>
                            <div className="w-full">
                                <Dropdown
                                    value={conditionId}
                                    onChange={(e) => setConditionId(e.value)}
                                    options={conditions}
                                    optionLabel="code"
                                    optionValue="masterCodeId"
                                    placeholder="Select Condition"
                                    className="w-full"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="px-2">
                        <div className="flex justify-content-between py-4">
                            <span className="p-input-icon-left flex align-items-center">
                                <h4 className="mb-0">Items Received</h4>
                            </span>
                        </div>

                        <DataTable
                            totalRecords={items.length}
                            value={items.reverse()}
                            className="erp-table"
                            paginator
                            rows={10}
                            removableSort
                        >
                            <Column header="LPN" field="REID" sortable />
                            <Column header="SKU" sortable sortField="product.sku" body={(item: any) => get(item, 'product.sku')} />
                            <Column header="Bin" sortable sortField="bin.binNumber" body={(item: any) => get(item, 'bin.binNumber')} />
                            <Column header="Condition" body={(item: any) => get(item, 'condition.code', '')} />
                            <Column header="" body={(item) => <div className='flex'>
                                <Button type="button" icon={'pi pi-trash'} className="p-button-sm p-button-text" style={{ color: 'red' }} onClick={() => confirmDelete(item)} />
                            </div>} />
                        </DataTable>
                    </div>
                </>
                }
            />
        </>
    )
}