import { memo } from "react";
import { Button } from "primereact/button";
import { Chart } from "primereact/chart";

interface ChatProps {
    key: any,
    title?: any,
    data: any,
    options: any
}

const MemoizedPieChart = memo(({ key, title = '', data, options }: ChatProps) => {
    return (<div>
        <p>{title}</p>
        <div key={key} className="flex justify-content-center"><Chart type="pie" height={'20rem'} data={data} options={options} /></div>
    </div>);
});

export default MemoizedPieChart;