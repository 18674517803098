import { useEffect, useRef, useState } from "react";
import { useAppContext } from "../../layout/AppWrapper";
import { useNavigate } from "react-router-dom";
import Sidebar from "../Sidebar";
import { ACTIONS } from "../../utils/constant";
import { CustomResponse } from "../../types";
import { GetCall } from "../../api/ApiKit";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { downloadExcel } from "../../utils/download";

interface ViewOptions {
    id: any,
    title: any;
    type: any;
    isVisible: boolean,
    onClose: (isLoad: boolean) => void
}

export default function SalesOrderView({ id = null, title, type, isVisible = false, onClose = (isLoad) => { } }: ViewOptions) {
    const navigate = useNavigate();
    const { user, isLoading, setLoading, setScroll, setAlert } = useAppContext();
    const [dialogVisible, setDialogVisible] = useState<boolean>(false);
    const [data, setData] = useState<any>(null);

    useEffect(() => {
        if (id) {
            setDialogVisible(true);
            fetchData(id)
        }
        else {
            setDialogVisible(false)
        }
    }, [id]);

    useEffect(() => {
        if (isVisible && id) {
            setDialogVisible(true);
            fetchData(id)
        }
        else {
            setDialogVisible(false)
        }
    }, [isVisible]);


    const fetchData = async (id: any) => {
        let apiUrl;
        if (type === 'PO') {
            apiUrl = `/company/${user?.company?.companyId}/purchase-orders/${id}/report`
        }
        else {
            apiUrl = `/company/${user?.company?.companyId}/sales-returns/${id}/report`
        }
        setLoading(true);
        const response: CustomResponse = await GetCall(apiUrl);
        if (response.code == 'SUCCESS') {
            setData(response.data)
        } else {
            setData(null);
            setAlert('error', response.message);
        }
        setLoading(false);
    }

    const downloadData = async (name: any, data: any[]) => {
        setLoading(true);
        await downloadExcel({ data, name });
        setLoading(false);
    }

    return <>
        <Sidebar
            isVisible={dialogVisible}
            action={ACTIONS.VIEW}
            width={'60vw'}
            footerTemplate={<></>}
            title={title}
            closeIcon={() => {
                setDialogVisible(false)
                onClose(false);
            }}
            content={<>
                {
                    type === 'PO' && data && <>
                        {
                            data.countMismatched && data.countMismatched.length > 0 && <div className="mb-5">
                                <div className="flex align-items-center justify-content-between mb-3">
                                    <p className="text-xl font-bold m-0">Count Discripancy</p>
                                    <Button icon={'pi pi-file-excel'} aria-label="Download Excel Sheet" size="small" onClick={() => downloadData('PO Count Mismatched', data.countMismatched.map((item: any) => ({
                                        'SKU': item['name'],
                                        'SKUId': item['sku'],
                                        'Expected Qnt': item['expectedCount'],
                                        'Received Qnt': item['receivedCount']
                                    })))}></Button>
                                </div>
                                <DataTable
                                    value={data.countMismatched}>
                                    <Column header="SKU" field="name" />
                                    <Column header="Expected Qnt" field="expectedCount" />
                                    <Column header="Received Qnt" field="receivedCount" />
                                </DataTable>
                            </div>
                        }
                        {
                            data.skuMismatched && data.skuMismatched.length > 0 && <div className="mb-5">
                                <div className="flex align-items-center justify-content-between mb-3">
                                    <p className="text-xl font-bold m-0">Product Discripancy</p>
                                    <Button icon={'pi pi-file-excel'} aria-label="Download Excel Sheet" size="small" onClick={() => downloadData('PO Product Mismatched', data.skuMismatched.map((item: any) => ({
                                        'SKU': item['name'],
                                        'SKUId': item['sku'],
                                        'Expected Qnt': item['expectedCount'],
                                        'Received Qnt': item['receivedCount']
                                    })))}></Button>
                                </div>
                                <DataTable
                                    value={data.skuMismatched}>
                                    <Column header="SKU" field="name" />
                                    <Column header="Expected Qnt" field="expectedCount" />
                                    <Column header="Received Qnt" field="receivedCount" />
                                </DataTable>
                            </div>
                        }
                    </>
                }
                {
                    type === 'RMA' && data && <>
                        {
                            data && data.countMismatched && data.countMismatched.length > 0 && <div className="mb-5">
                                <div className="flex align-items-center justify-content-between mb-3">
                                    <p className="text-xl font-bold m-0">Count Discripancy</p>
                                    <Button icon={'pi pi-file-excel'} aria-label="Download Excel Sheet" size="small" onClick={() => downloadData('RMA Count Mismatched', data.countMismatched.map((item: any) => ({
                                        'SKU': item['name'],
                                        'SKUId': item['sku'],
                                        'Expected Qnt': item['expectedCount'],
                                        'Received Qnt': item['receivedCount']
                                    })))}></Button>
                                </div>
                                <DataTable
                                    value={data.countMismatched}>
                                    <Column header="SKU" field="name" />
                                    <Column header="Expected Qnt" field="expectedCount" />
                                    <Column header="Received Qnt" field="receivedCount" />
                                </DataTable>
                            </div>
                        }
                        {
                            data.skuMismatched && data.skuMismatched.length > 0 && <div className="mb-5">
                                <div className="flex align-items-center justify-content-between mb-3">
                                    <p className="text-xl font-bold m-0">Product Discripancy</p>
                                    <Button icon={'pi pi-file-excel'} aria-label="Download Excel Sheet" size="small" onClick={() => downloadData('RMA Product Mismatched', data.skuMismatched.map((item: any) => ({
                                        'SKU': item['name'],
                                        'SKUId': item['sku'],
                                        'Expected Qnt': item['expectedCount'],
                                        'Received Qnt': item['receivedCount']
                                    })))}></Button>
                                </div>
                                <DataTable
                                    value={data.skuMismatched}>
                                    <Column header="SKU" field="name" />
                                    <Column header="Expected Qnt" field="expectedCount" />
                                    <Column header="Received Qnt" field="receivedCount" />
                                </DataTable>
                            </div>
                        }
                    </>
                }
            </>}
        />
    </>
}