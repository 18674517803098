import { Link, useNavigate } from "react-router-dom";
import { useAppContext } from "../../layout/AppWrapper";
import { useEffect, useRef, useState } from "react";
import Sidebar from "../Sidebar";
import { ACTIONS } from "../../utils/constant";
import { CustomResponse, SalesReturnItems, SalesReturns } from "../../types";
import { GetCall } from "../../api/ApiKit";
import { buildQueryParams } from "../../utils/uitl";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { isMoment } from "moment-timezone";
import moment from "moment";
import { get, sumBy } from "lodash";
import { IconField } from "primereact/iconfield";
import { Panel } from "primereact/panel";
import { Badge } from "primereact/badge";
import SalesReturnsEditor from "../Editors/SalesReturnEditor";
import SalesReceiveEditor from "../Editors/SalesReceiveEditor";

interface ViewOptions {
    title?: any,
    rmaId: any,
    isVisible: boolean,
    onClose: (isLoad: boolean) => void
}

export default function SalesReturnView({ title = '', rmaId = null, isVisible = false, onClose = (isLoad) => { } }: ViewOptions) {
    const navigate = useNavigate();
    const panelRef = useRef<Panel>(null);
    const { user, isLoading, setLoading, setScroll, setAlert } = useAppContext();
    const [dialogVisible, setDialogVisible] = useState<boolean>(false);

    const [isEdit, setEdit] = useState<boolean>(false);
    const [isReceive, setIsReceive] = useState<boolean>(false);
    const [salesReturn, setSalesReturn] = useState<SalesReturns | null>(null);

    useEffect(() => {
        if (rmaId) {
            setDialogVisible(true);
            fetchRMADetails(rmaId)
        }
        else {
            setDialogVisible(false)
        }
    }, [rmaId]);

    useEffect(() => {
        if (isVisible && rmaId) {
            setDialogVisible(true);
            fetchRMADetails(rmaId)
        }
        else {
            setDialogVisible(false)
            setEdit(false);
        }
    }, [isVisible]);


    const fetchRMADetails = async (_rmaId: any) => {
        let params: any = {
            filters: {
                rmaId: _rmaId
            },
            include: 'customer,status,items,so'
        };
        setLoading(true);
        const queryString = buildQueryParams(params);
        const response: CustomResponse = await GetCall(`/company/${user?.company?.companyId}/sales-returns?${queryString}`);
        if (response.code == 'SUCCESS') {
            setSalesReturn(response.data[0])
        } else {
            setSalesReturn(null);
            setAlert('error', response.message);
        }
        setLoading(false);
    }

    const panelHeaderTemplate = (options: any) => {
        const className = `${options.className} justify-content-space-between`;
        return (
            <div className={className} onClick={() => {
                panelRef.current?.toggle(undefined)
            }}>
                <div className='flex gap-4'>
                    <div className="flex align-items-center cursor-pointer gap-2" onClick={(e) => {
                        e.stopPropagation();
                        panelRef.current?.expand(e);
                    }}>
                        <span className="font-bold">Sales Order</span>
                    </div>
                </div>
                <div>
                    {options.togglerElement}
                </div>
            </div>
        );
    };

    const renderProduct = (item: SalesReturnItems) => {
        return <div>
            <span className='text-sm'>LPN: {get(item, 'pItem.REID')}</span><br />
            <label className='text-900'>{get(item, 'product.name')}</label>
        </div>
    };

    return <>
        <Sidebar
            isVisible={dialogVisible}
            action={ACTIONS.VIEW}
            width={'60vw'}
            footerTemplate={<></>}
            title={salesReturn?.rmaNumber ? salesReturn.rmaNumber : title}
            closeIcon={() => {
                setDialogVisible(false)
                onClose(false);
            }}
            content={<>
                <div>
                    <div className='flex w-full absolute bg-ligthgrey br-top br-bottom z-2' style={{ top: '4rem', left: 0 }}>
                        <div className='page-menu-item p-3 pl-5 br-right cursor-pointer' onClick={() => setEdit(true)}><i className="pi pi-pencil"></i> Edit</div>
                        {salesReturn?.receivedDate == null && <div className='page-menu-item p-3 br-right cursor-pointer' onClick={() => setIsReceive(true)}>Receive</div>}
                        {/* <div className='page-menu-item p-3 br-right cursor-pointer' onClick={(event) => { if (createMenuRef.current) { createMenuRef.current.toggle(event) } }}><i className="pi pi-ellipsis-v"></i></div> */}
                    </div>
                    <div className='my-7 pr-2'>
                        <div className='grid justify-content-between p-2 mb-2'>
                            <div>
                                <h4>Sales Return</h4>
                                <p className='mb-1'>RMA # <strong>{salesReturn?.rmaNumber}</strong></p>
                                <p className="m-0">Sales Order # <strong><Link to={`/sales-orders?soId=${salesReturn?.soId}`} className='text-blue'>{get(salesReturn, 'so.soNumber', '')}</Link></strong></p>
                                <p className="m-0">Status <strong>{get(salesReturn, 'status.code')}</strong></p>
                            </div>
                            <div className='text-right'>
                                <p>Billing Address</p>
                                <p className='text-blue cursor-pointer'><strong>{get(salesReturn, 'customer.name', '')}</strong></p>
                            </div>
                        </div>

                        <div className="mt-4">
                            <h5>Line Items</h5>
                            <DataTable
                                scrollable
                                showGridlines
                                value={salesReturn?.items || []}
                                selectionMode="single"
                                dataKey="productId"
                                scrollHeight="70%"
                                style={{ height: '80%' }}
                            >
                                <Column header='#' body={(option, { rowIndex }) => rowIndex + 1} style={{ width: 50 }}></Column>
                                <Column field="skuId" header="Product & SKU" body={(data) => renderProduct(data)}></Column>
                                {/* <Column field="condition" header="Condition" style={{ width: 80, textAlign: 'right' }}></Column> */}
                                <Column field="price" header="Price" style={{ width: 80, textAlign: 'right' }}></Column>
                            </DataTable>
                        </div>
                        <div className="grid mt-3">
                            <div className="col-5 col-offset-7">
                                <div className="flex justify-content-between align-items-baseline">
                                    <p className="font-semibold">Sub Total</p>
                                    <p className="font-bold">${sumBy(get(salesReturn, 'items', []), 'price')}</p>
                                </div>
                            </div>
                        </div>
                        <div className="mt-3">
                            <h5>Remarks</h5>
                            <p>{salesReturn?.note || 'N/A'}</p>
                        </div>
                    </div>
                </div>
            </>
            }
        />
        {
            isEdit && <SalesReturnsEditor
                isEdit={true}
                action={ACTIONS.EDIT}
                soId={salesReturn?.soId}
                isVisible={true}
                salesReturn={salesReturn}
                onClose={function (isLoad: boolean): void {
                    setEdit(false)
                    if (isLoad) {
                        fetchRMADetails(salesReturn?.rmaId)
                    }
                }}
            />
        }

        {
            isReceive && <SalesReceiveEditor
                isVisible={true}
                salesReturn={salesReturn}
                onClose={(isLoad) => {
                    setIsReceive(false);
                }} />
        }
    </>
}