import { useEffect, useRef, useState } from "react";
import { InputNumber } from "primereact/inputnumber";
import { Column, ColumnBodyOptions } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { MultiSelect } from "primereact/multiselect";
import { filter, find, get, groupBy, map, set, sumBy } from "lodash";
import moment, { isMoment } from "moment";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { useAppContext } from "../../layout/AppWrapper";
import Sidebar from "../Sidebar";
import { ACTIONS, constant } from "../../utils/constant";
import CustomPanel from "../CustomPanel";
import { Category, CustomResponse, MasterCode, Product, PurchaseItem, PurchaseOrder, SalesOrderItem, Vendor, Warehouse } from "../../types";
import { GetCall, PostCall } from "../../api/ApiKit";


interface BackOrderEditorOptions {
    title?: string,
    soId: any,
    isVisible: boolean,
    items: SalesOrderItem[],
    action?: any,
    onClose: (isLoad: boolean) => void
}

const defaultLineItem: PurchaseItem = {
    poId: null,
    poItemId: null,
    companyId: null,
    categoryId: null,
    productId: null,
    gradeId: null,
    isCrossDock: false,
    quantity: 0,
    price: null,
    rowId: `${Date.now()}_${Math.random()}`,
    skuId: null,
    internalGrade: null,
    batteryHealthIds: null
};
const defaultPurchaseOrder: PurchaseOrder = {
    poId: null,
    poNumber: '',
    companyId: null,
    warehouseId: null,
    vendorId: null,
    categoryIds: null,
    poDate: null,
    approxDeliveryDate: null,
    trackingTypeId: null,
    trackingId: null,
    statusId: null,
    note: null,
    quantity: 0,
    totalPrice: 0,
    paid: 0,
    items: []
}


export default function BackOrderEditor({ title = 'Backorder', soId = null, isVisible = false, action = '', items = [], onClose = (isLoad) => { } }: BackOrderEditorOptions) {
    const { user, isLoading, setLoading, setScroll, setAlert } = useAppContext();
    const [dialogVisible, setDialogVisible] = useState<boolean>(false);

    const containerRef = useRef(null);
    const [isSubmitted, setSubmitted] = useState<boolean>(false);
    const [pOrder, setPurchaseOrder] = useState<PurchaseOrder>({ ...defaultPurchaseOrder });
    const [warehouses, setWarehouses] = useState<Warehouse[]>([]);
    const [vendors, setAllVendors] = useState<Vendor[]>([]);
    const [categories, setCategories] = useState<Category[]>([]);
    const [trackings, setTrackings] = useState<MasterCode[]>([]);
    const [statuses, setStatuses] = useState<MasterCode[]>([]);
    const [paymentTerms, setPaymentTerms] = useState<MasterCode[]>([]);
    const [grades, setGrades] = useState<any[]>([]);
    const [products, setProducts] = useState<any[]>([]);
    const [viewProducts, setViewProducts] = useState<any[]>([]);

    useEffect(() => {
        if (isVisible) {
            setDialogVisible(true);
            fetchCarriers();
            fetchAllVendors();
            fetchGrades();
            fetchLocations();
            let _items: PurchaseItem[] = [];
            items.forEach((element, index) => {
                let qnt = (get(element, 'stockAvail', 0) - (element.quantity - element.packed));
                if (qnt < 0) {
                    _items.push({
                        categoryId: element.product.categoryId,
                        poId: null,
                        poItemId: null,
                        companyId: null,
                        productId: element.product.productId,
                        gradeId: null,
                        isCrossDock: null,
                        quantity: qnt > 0 ? qnt : (element.quantity - element.packed),
                        product: element.product,
                        price: undefined,
                        rowId: index
                    })
                }
            });
            if (_items.length > 0) {
                let grouped = groupBy(_items, 'productId')
                const groupData = Object.keys(grouped).map((key, index) => ({
                    ...grouped[key][0],
                    productId: key,
                    rowId: index,
                    quantity: sumBy(grouped[key], 'quantity'),
                }));
                updateItem('items', groupData)
            }
        }
        else {
            setDialogVisible(false)
        }
    }, [isVisible]);


    const fetchCarriers = async () => {
        if (!user?.company?.companyId) {
            return;
        }
        setLoading(true);
        const response: CustomResponse = await GetCall(`/company/${user?.company?.companyId}/master-codes?codeType=${constant.SYSTEM_MSTR_CODE.shippingCarrier}`);
        if (response.code == 'SUCCESS') {
            setTrackings(response.data);
        } else {
            setTrackings([]);
        }
        setLoading(false);
    };

    const fetchGrades = async () => {
        if (!user?.company?.companyId) {
            return;
        }
        setLoading(true);
        const response: CustomResponse = await GetCall(`/company/${user?.company?.companyId}/master-codes?codeType=${constant.SYSTEM_MSTR_CODE.grades}`);
        if (response.code == 'SUCCESS') {
            setGrades(response.data);
        } else {
            setGrades([]);
        }
        setLoading(false);
    };

    const fetchLocations = async () => {
        if (!user?.company?.companyId) {
            return;
        }
        setLoading(true);
        const response: CustomResponse = await GetCall(`/company/${user?.company?.companyId}/warehouses?limit=500`);
        if (response.code == 'SUCCESS') {
            setWarehouses(response.data);
        } else {
            setWarehouses([]);
        }
        setLoading(false);
    };

    const fetchAllVendors = async () => {
        if (!user?.company?.companyId) {
            return;
        }
        setLoading(true);
        const response: CustomResponse = await GetCall(`/company/${user?.company?.companyId}/vendors?limit=500`);
        if (response.code == 'SUCCESS') {
            setAllVendors(response.data);
        } else {
            setAllVendors([]);
        }
        setLoading(false);
    };

    const closeIcon = () => {
        onClose(false);
    }

    const onSave = async () => {
        if (!pOrder.warehouseId || !pOrder.vendorId || !pOrder.poDate || !isMoment(moment(pOrder.poDate))) {
            setAlert('error', 'Please provide all mandaotry fields (marked with red asterisk *)');
            return;
        }
        const data: any = { ...pOrder, soId: soId };
        data.categoryIds = map(data.items, 'categoryId');
        const response: CustomResponse = await PostCall(`/company/${user?.company?.companyId}/purchase-orders`, data);
        if (response.code == 'SUCCESS') {
            onClose(true);
            setAlert('success', 'Backorder placed successfully');
        } else {
            setAlert('error', response.message);
        }
    }

    const updateItem = async (key: string, value: any) => {
        const _pOrder: PurchaseOrder = JSON.parse(JSON.stringify(pOrder));
        set(_pOrder, key, value);
        setPurchaseOrder(_pOrder);
    }

    const removeItem = (productId: number | null) => {
        if (!productId) return;
        let _items = [...pOrder.items];
        _items = _items.filter((item) => item.productId != productId);
        updateItem('items', _items);
    };

    const productOptionTemplate = (option: PurchaseItem) => {
        return (
            <div className="flex align-items-start flex-column">
                <div className="text-xs text-grey">{option.product?.skuId}</div>
                <div className="text-base">{option.product?.name}</div>
            </div>
        );
    };

    const renderGrades = (item: PurchaseItem) => {
        if (!item?.productId) {
            return <></>;
        }
        return (
            <Dropdown
                value={item?.gradeId}
                onChange={(e) => updateItem(`items.${item.rowId}.gradeId`, e.value)}
                options={grades}
                optionLabel="code"
                optionValue="masterCodeId"
                placeholder="Select a Grade"
                className="w-full"
            />
        );
    };

    const renderCrossDock = (item: PurchaseItem) => {
        if (!item?.productId) {
            return <></>;
        }
        return (
            <div className="flex justify-content-center mr-6">
                <Checkbox
                    checked={item.isCrossDock || false}
                    onChange={(e) => updateItem(`items.${item.rowId}.isCrossDock`, e.checked)}
                />
            </div>
        );
    };

    const renderQuantity = (item: PurchaseItem) => {
        if (!item?.productId) {
            return <></>;
        }
        return (
            <div>
                <InputNumber
                    value={item.quantity}
                    onChange={(e) => updateItem(`items.${item.rowId}.quantity`, e.value)}
                    inputClassName="text-base"
                    inputStyle={{ width: '80px' }}
                />
            </div>
        );
    };


    const renderRate = (item: PurchaseItem) => {
        if (!item?.productId) {
            return <></>;
        }
        return (
            <InputNumber
                value={item.price}
                onChange={(e) => updateItem(`items.${item.rowId}.price`, e.value)}
                mode="currency"
                currency="USD"
                locale="en-US"
                placeholder="Price"
                inputClassName="text-base"
                inputStyle={{ width: '150px' }}
            />
        );
    };


    const renderTotal = (item: PurchaseItem) => {
        if (!item?.productId) {
            return <></>;
        }
        const formattedPrice = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0, // Optional: set to 2 if you want cents (e.g., $4,000.00)
        }).format((item.price || 0) * (item.quantity || 0));
        return <div className="flex align-items-center">{formattedPrice}</div>;
    };


    const renderCross = (item: PurchaseItem) => {
        if (!item?.productId) {
            return <></>;
        }
        return (
            <div className="flex align-items-center">
                <Button
                    icon="pi pi-times"
                    severity="danger"
                    aria-label="Cancel"
                    size="small"
                    onClick={() => removeItem(item.productId)}
                />
            </div>
        );
    };

    return (
        <Sidebar
            isVisible={dialogVisible}
            action={'add'}
            width={'60vw'}
            title={title}
            closeIcon={closeIcon}
            onSave={onSave}
            content={<>
                <div className="grid" ref={containerRef}>
                    <div className="col-4">
                        <label className="mb-2 block">
                            Warehouse<span className='text-red'>*</span>
                        </label>
                        <Dropdown
                            value={pOrder.warehouseId}
                            filter
                            onChange={(e) => updateItem('warehouseId', e.value)}
                            options={warehouses}
                            optionLabel="name"
                            optionValue="warehouseId"
                            placeholder="Select a Location"
                            className={`w-full ${isSubmitted && !pOrder.warehouseId ? 'p-invalid' : ''}`}
                            required={true}
                        />
                    </div>

                    <div className="col-4">
                        <label className="mb-2 block">
                            Vendor<span className='text-red'>*</span>
                        </label>
                        <Dropdown
                            value={pOrder.vendorId}
                            filter
                            onChange={(e) => updateItem('vendorId', e.value)}
                            options={vendors}
                            optionLabel="name"
                            optionValue="vendorId"
                            placeholder="Select a Vendor"
                            className={`w-full ${isSubmitted && !pOrder.vendorId ? 'p-invalid' : ''}`}
                            required={true}
                        />
                    </div>

                    <div className="col-4">
                        <label className="mb-2 block">
                            PO Date<span className='text-red'>*</span>
                        </label>
                        <Calendar appendTo={'self'} value={pOrder.poDate ? new Date(pOrder.poDate) : null} onChange={(e) => updateItem('poDate', moment(e.value).toDate())} placeholder="PO Date" className="w-full" showIcon required={true} />
                    </div>
                    <div className="col-4">
                        <label className="mb-2 block">
                            Delivery Date
                        </label>
                        <Calendar appendTo={'self'} value={pOrder.approxDeliveryDate ? new Date(pOrder.approxDeliveryDate) : null} onChange={(e) => updateItem('approxDeliveryDate', moment(e.value).toDate())} placeholder="PO Delivery Date" className="w-full" showIcon />
                    </div>
                    <div className="col-4">
                        <label className="mb-2 block">
                            Shipment carrier
                        </label>
                        <Dropdown value={pOrder.trackingTypeId} onChange={(e) => updateItem('trackingTypeId', e.value)} options={trackings} optionLabel="code" optionValue="masterCodeId" placeholder="Shipment carrier" className="w-full" />
                    </div>
                    <div className="col-4">
                        <label className="mb-2 block">
                            Primary Tracking Number
                        </label>
                        <InputText value={pOrder.trackingId} onChange={(e) => updateItem('trackingId', e.target.value)} placeholder="Tracking Number" className="w-full" />
                    </div>
                </div>
                <div className="mt-4">
                    <h5>Line Items</h5>
                    {pOrder.items && pOrder.items.length === 0 && <div className="placeholder h-8rem pointer-none flex align-items-center justify-content-center">Line items will be displayed here</div>}
                    {
                        pOrder.items.length > 0 && <DataTable
                            scrollable
                            value={pOrder.items}
                            selectionMode="single"
                            dataKey="productId"
                            scrollHeight="70%"
                            style={{ width: '100%', height: '80%' }}
                        >
                            <Column field="poDate" header="Product & SKU" body={(data, options: ColumnBodyOptions) => productOptionTemplate(data)} style={{ maxWidth: 100 }} />
                            <Column field="poDate" header="Vendor Grades" body={renderGrades} style={{ maxWidth: 100 }} />
                            <Column field="poDate" header="Cross Dock" body={renderCrossDock} style={{ maxWidth: 70 }} />
                            <Column field="poDate" header="Quantity" body={renderQuantity} style={{ maxWidth: 50 }} />
                            <Column field="poNumber" header="Rate" body={renderRate} style={{ maxWidth: 100 }} />
                            <Column field="poNumber" header="Total" body={renderTotal} style={{ maxWidth: 50 }} />
                        </DataTable>
                    }
                    <div className="grid mt-3">
                        <div className="col-9"></div>
                        <div className="col-3">
                            <div className="flex justify-content-between">
                                <p className="font-semibold">Total</p>
                                <p className="font-bold"> {new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: 'USD',
                                    minimumFractionDigits: 0, // Optional: set to 2 if you want cents (e.g., $2,000.00)
                                }).format(sumBy(pOrder.items, (item) => item.quantity * item.price))}
                                </p>
                            </div>
                            <div className="flex justify-content-between">
                                <p className="font-bold">Paid</p>
                                <InputNumber
                                    mode="currency"
                                    currency="USD"
                                    locale="en-US"
                                    value={pOrder.paid}
                                    onChange={(e) => {
                                        if (e.value) {
                                            updateItem('paid', e.value);
                                        } else {
                                            updateItem('paid', 0);
                                        }
                                    }}
                                    inputClassName="text-base font-bold"
                                    inputStyle={{ width: '130px' }}
                                />
                            </div>
                            <hr className="mb-3 mx-3 border-top-1 border-none surface-border" />
                            <div className="flex justify-content-between">
                                <p className="font-semibold">Balance</p>
                                <p className="font-bold">
                                    {new Intl.NumberFormat('en-US', {
                                        style: 'currency',
                                        currency: 'USD',
                                        minimumFractionDigits: 0, // Optional: set to 2 if you want cents (e.g., $2,000.00)
                                    }).format(sumBy(pOrder.items, (item) => item.quantity * item.price) - pOrder.paid)}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="grid mt-3">
                        <h5>Remarks</h5>
                        <InputTextarea className="w-full" value={pOrder.note || ''} onChange={(e) => updateItem('note', e.target.value)} rows={5} cols={30} style={{ resize: 'none' }} />
                    </div>
                </div>
            </>
            }
        />
    )
}