


import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { useAppContext } from '../../../layout/AppWrapper';
import { LayoutContext } from '../../../layout/context/layoutcontext';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { InputText } from 'primereact/inputtext';
import { CustomResponse } from '../../../types';
import { GetCall, PostCall } from '../../../api/ApiKit';
import { debounce, get } from 'lodash';

const ObjectAllocationPage = () => {
    const { user, isLoading, setLoading, setScroll, setAlert } = useAppContext();
    const { layoutState } = useContext(LayoutContext);
    const [isShowSplit, setIsShowSplit] = useState<boolean>(false);
    const [source, setSource] = useState<string>('');
    const [destination, setDestination] = useState<string>('');
    const [sourceDetails, setSourceDetail] = useState<any>(null);
    const [destinationDetails, setDestinationDetails] = useState<any>(null);

    useEffect(() => {
        setScroll(false);
        return () => {
            setScroll(true);
        };
    }, [])

    const handleKeyPress = (event: any) => {
        if (event.key === "Enter") {
            console.log("Scanned Value:", event.target.value);
            // fetchBarcodeDetails(event.target.value)
            if (source && destination) {
                onSave()
            }
        }
    };

    const fetchBarcodeDetails = async (barcode: any) => {
        setLoading(true);
        const companyId = get(user, 'company.companyId');
        const response: CustomResponse = await GetCall(`/company/${companyId}/barcode/${barcode}`);
        if (response.code == 'SUCCESS') {
            if (source == barcode) {
                setSourceDetail(response.data)
            }
            if (destination == barcode) {
                setDestinationDetails(response.data)
            }
        } else {
            if (source == barcode) {
                setSourceDetail(null)
            }
            if (destination == barcode) {
                setDestinationDetails(null)
            }
            // setAlert('error', response.message);
        }
        setLoading(false);
    }

    // useEffect(() => {
    //     if (source && destination) {
    //         onSave()
    //     }
    // }, [source, destination])

    const onSave = async () => {
        if (source && destination) {
            setLoading(true);
            const companyId = get(user, 'company.companyId');
            const data = {
                sourceId: source,
                targetId: destination
            }
            const response: CustomResponse = await PostCall(`/company/${companyId}/object-allocation`, data);
            if (response.code == 'SUCCESS') {
                setAlert('success', response.message);
                setSource('')
                setDestination('')
            } else {
                setAlert('error', response.message);
            }
            setLoading(false);
        }
        else {
            setAlert('error', 'Please provide valid source & destination code');
        }
    }

    return (
        <>
            <div className="grid">
                <div className="col-12">
                    <div className={`panel-container ${isShowSplit ? (layoutState.isMobile ? 'mobile-split' : 'split') : ''}`}>
                        <div className="left-panel">
                            <div className="flex justify-content-between p-4">
                                <span className="p-input-icon-left flex align-items-center">
                                    <h4 className="mb-0">Object Allocation</h4>
                                </span>
                            </div>
                            <div className='px-4'>
                                <div className="flex grid gap-3">
                                    <div className='col-5'>
                                        <label htmlFor="name3" className="w-full">
                                            Source<span className='text-red'>*</span>
                                        </label>
                                        <IconField className='mt-2'>
                                            <InputIcon className="pi pi-qrcode"> </InputIcon>
                                            <InputText autoFocus className='w-full' onChange={(e) => setSource(e.target.value)} onKeyDown={handleKeyPress} />
                                        </IconField>
                                    </div>
                                    <div className='flex align-items-center'>
                                        <i className='pi pi-arrow-circle-right text-xl'></i>
                                    </div>
                                    <div className='col-5'>
                                        <label htmlFor="name3" className="w-full ">
                                            Destination<span className='text-red'>*</span>
                                        </label>
                                        <IconField className='mt-2'>
                                            <InputIcon className="pi pi-qrcode"> </InputIcon>
                                            <InputText className='w-full' onChange={(e) => setDestination(e.target.value)} onKeyDown={handleKeyPress} />
                                        </IconField>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ObjectAllocationPage;
